import React from "react";
import Tie from "../images/tie.svg";

const PageNotFoundPage: React.FunctionComponent = () => (
    <div className="min-h-screen px-4 py-16 bg-white sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="mx-auto max-w-max">
            <main className="sm:flex">
                <p className="text-4xl font-extrabold text-black sm:text-5xl">
                    <Tie className={"h-8 mt-2 w-auto"} />
                </p>
                <div className="sm:ml-6">
                    <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                        <h1 className="font-serif text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                            Die Seite konnte nicht gefunden werden.
                        </h1>
                        <p className="mt-1 text-base text-gray-500">Bitte überprüfen Sie die URL und versuchen Sie es erneut.</p>
                    </div>
                    <div className="flex mt-10 space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                        <a
                            href="/"
                            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-black border border-transparent shadow-sm hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                        >
                            Zurück zur Startseite
                        </a>
                        <a
                            href="/contact"
                            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-black border border-transparent hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                        >
                            Kundendienst
                        </a>
                    </div>
                </div>
            </main>
        </div>
    </div>
);

export default PageNotFoundPage;
